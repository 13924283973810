import React, { useEffect, useState } from 'react';
import axios from 'axios';
import css from './VerificationDefault.css';
import { FormattedMessage } from 'react-intl';
import SingpassButton from '../../../assets/SinpassPrimaryButton.svg';
import {
  createRawPropertiesForGTM,
  getEventsByBucket,
} from '../../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../../util/analyticsConstants';

const VerificationDefault = ({ currentUser, selectVerificationProcess }) => {
  const [configData, setConfigData] = useState({});
  const [trxnNumber, setTrxnNumber] = useState(
    Math.floor(Math.random() * 100000),
  );
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if(window) {
       window.scrollTo(0, 0)
     }
  }, [])

  useEffect(() => {
    let sessionUserData = JSON.parse(sessionStorage.getItem('CURRENT_USER'));

    if(currentUser && currentUser.id.uuid) {
      setTrxnNumber(`${currentUser.id.uuid}:${trxnNumber}`);
    }
    if(sessionUserData && typeof sessionUserData === 'object') {
      setTrxnNumber(`${sessionUserData.id.uuid}:${trxnNumber}`);
    }
    if(sessionUserData && typeof sessionUserData !== 'object') {
      setTrxnNumber(`${sessionUserData}:${trxnNumber}`);
    }

    const getEnvData = async () => {
      try {
        const envResponse = await axios.get(
          `${process.env.REACT_APP_API_SERVER_URL}/api/singpass/config?userId=${currentUser ? currentUser.id.uuid : sessionUserData ? sessionUserData.id.uuid: ''}`
        );

        setConfigData(envResponse.data);
      } catch (error) {

      }
    };

    
    getEnvData();
  }, [setTrxnNumber, setUserId]);

  const handleSingpassClick = async () => {

    try {
      const response = await getEventsByBucket('guestverify');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.GUEST_VERIFY_OPEN_FORM,
        eventData: response.data,
        props: {
          guest: currentUser
        },
        user: currentUser,
        userId: currentUser ? currentUser.id.uuid : null
      });
      // pushGTMVerifiedEmailPhone(properties, EVENT_SIGNUP_VERIFIED_EMAIL);
    } catch (error) {
      console.error('Error fetching events or triggering GTM event:', error);
    }

    if (configData.authApiUrl) {
      let authoriseUrl =
        configData.authApiUrl +
        '?client_id=' + configData.clientId +
        '&scope=' + configData.scopes +
        '&purpose_id=' + configData.purposeId +
        '&code_challenge=' + configData.codeChallenge +
        '&code_challenge_method=' + configData.method +
        '&redirect_uri=' + configData.redirectUrl;

        // window.localStorage.setItem('singpassCodeVerfier', configData.codeVerifier);
        window.location.href = authoriseUrl;
    }
  };

  return (
    <>
      <div className={css.verificationDescription}>
        <FormattedMessage id="Verification.VerifcationDescription" />
      </div>
      <div className={css.verifyBox}>
        <div className={css.verifyBoxTop}>
          <div className={css.recomendedBanner}>
            <span>
              <FormattedMessage id="Verification.VerifcationRecomendedBanner" />
            </span>
          </div>
          <div className={css.verifyMethodDetails}>
            <FormattedMessage id="Verification.SingpassDesc" />
            &nbsp;
            <a
              href="https://api.singpass.gov.sg/library/myinfo/business/introduction"
              className={css.learnMoreLink}
              target="_blank"
            >
              <FormattedMessage id="NRICSettingForm.learnMore" />
            </a>
          </div>
          <div className={css.verifyMethodAction}>
            <a className={css.verifySingpasButton} onClick={handleSingpassClick}>
              <img src={SingpassButton} alt="React Logo" />
            </a>
          </div>
        </div>
        <div className={css.verifyBoxBottom}>
          <ul>
            <li>
              <FormattedMessage id="Verification.SingpassPros1" />
            </li>
            <li>
              <FormattedMessage id="Verification.SingpassPros2" />
            </li>
          </ul>
        </div>
      </div>
      <div className={css.verifyBox}>
        <div className={css.verifyBoxTop}>
          <div className={css.verifyMethodDetails}>
            <FormattedMessage id="Verification.ManualDesc" />
          </div>
          <div className={css.verifyMethodAction}>
            <button className={css.verifyManualButton} onClick={() => selectVerificationProcess()}>
              Complete Verification
            </button>
          </div>
        </div>
        <div className={css.verifyBoxBottom}>
          <ul>
            <li>
              <FormattedMessage id="Verification.ManualPros1" />
            </li>
            <li>
              <FormattedMessage id="Verification.ManualPros2" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default VerificationDefault;