import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import css from './SingpassCallback.css';

const SingpassCallback = props => {
  const location = useLocation();
  const authCode = new URLSearchParams(location.search).get('code');

  const history = useHistory();

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const sessionUserData = JSON.parse(sessionStorage.getItem('CURRENT_USER'));
        const flexUserId = sessionUserData.id.uuid;

        const clientResponse = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/api/singpass/getIndentityPersonData`,
          {
            authCode,
            flexUserId
          }
        );

        // alert('client response should come', clientResponse)
        if (window.ReactNativeWebView) {
          let data = JSON.stringify({
            status: true,
          });
          window.ReactNativeWebView.postMessage(window.location.href);
        } else {
          history.push('/account/driving-verification/singpass-profile');
        }
      } catch (error) {
        if (window.ReactNativeWebView) {
          let data = JSON.stringify({status: false});
          window.ReactNativeWebView.postMessage(window.location.href);
        } else {

          history.push('/account/driving-verification');
        }
      }
    };
    if (authCode) {
      fetchClientData();
    }
  }, [authCode]);

  return (
    <>
      <div className=''>
        <div className={css.loader}></div>
        <h3>We are fetching your verification data from Singpass...</h3>
      </div>

    </>
  );
};

export default SingpassCallback;